import React from 'react'


import Layout from '../components/layout'
import SEO from '../components/seo'
import Summary from '../components/summary/index'
import WorkedWith from '../components/workedWith/index'
import ProjectsList from '../components/projectsList/index'
import ThoughtsOn from '../components/thoughtsOn/index'

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`UX`, `Freelance`, `Designer`, `Researcher`]}
        title="Home"
      />
      <Summary />
      <WorkedWith />
      <ProjectsList />
      <ThoughtsOn />
    </Layout>
  )
}

export default IndexPage
