import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const WorkedWith = () => {
  const clients = useStaticQuery(graphql`
    query {
      allContentfulCompaniesIveWorkedWith(sort:{ fields: datesWorkedAtCompany, order: DESC}, limit: 5) {
        edges {
          node {
            id
            logo {
              title
              description
              file {
                url
              }
            }
          }
        }
      }
    }
  `)
  const clientTitle = clients.allContentfulCompaniesIveWorkedWith.edges.map(client => {
    return (
      <div
        className="h-8 md:mr-6 lg:w-auto lg:ml-6 mr-6"
        key={client.node.id}
      >
        <img alt={client.node.logo.description} className="h-8" src={client.node.logo.file.url} />
      </div>
    )
  })

  return (
    <section className="mb-20 hidden text-center md:block" id="worked-with">
      <h3 className="text-3xl font-bold inline-block mb-8">Who I've worked with</h3>
      <div className="flex -mx-2">{clientTitle}</div>
    </section>
  )
}

export default WorkedWith
