import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'

const Summary = () => {

  const logo = useStaticQuery(graphql`
    query {
      contentfulSummaryInfo {
        secondarySiteLogo {
          title
          file {
            url
          }
        }
      }
    }
  `)
  return (
    <section id="about-summary" className="mb-20 text-center">
      <img src={logo.contentfulSummaryInfo.secondarySiteLogo.file.url} alt={logo.contentfulSummaryInfo.secondarySiteLogo.title} className="max-w-sm m-auto mb-5" />
      <h3 className="text-4xl leading-tight inline-block mb-5 font-bold w-full">
        A designer who specialises in user experience
      </h3>
      <p className="mb-8 text-xl xl:max-w-xl md:text-center md:mx-auto">
        With every project, I am always <span className="font-bold">thoughtful</span> in my approach, showing consideration and understanding for the needs of people.
      </p>
      <Link className="underline font-semibold hover:font-bold" to="/about">
        <button className="hover:bg-teal-600 bg-gray-900 text-white font-bold py-2 px-4 rounded-full">
          Learn more about me <FaChevronRight className="inline" />
        </button>
      </Link>
    </section>
  )
}

export default Summary
