import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'

const ProjectsList = () => {
  const projects = useStaticQuery(graphql`
    query {
      allContentfulProject(
        limit: 2
        filter: { homePageItem: { eq: true }}
        ) {
        edges {
          node {
            title
            id
            slug
            leadText
            articleImage {
              description
              fluid {
                src
              }
            }
          }
        }
      }
    }
  `)
  const projectTile = projects.allContentfulProject.edges.map(project => {
    return (

      <div className="w-full md:w-1/2 md:px-2 mb-4" key={project.node.id}>
        <Link to={`/projects/${project.node.slug}`}>
          <div className="overflow-hidden hover:shadow-lg">
            <img className="h-auto" src={project.node.articleImage.fluid.src} alt={project.node.articleImage.description} />
            <div className="px-6 py-4 bg-gray-100">
              <h4 className="font-bold text-xl mb-2">{project.node.title}</h4>
              <p className="text-gray-700 text-base">{project.node.leadText}</p>
            </div>
          </div>
        </Link>
      </div >

    )
  })

  return (
    <section id="latest-projects" className="mb-20 text-center">
      <h3 className="text-3xl font-bold mb-8">Projects I've worked on</h3>
      <div className="flex flex-wrap -mx-2 mt-3 text-left">{projectTile}</div>
      <Link className="underline font-semibold hover:font-bold" to="/projects">
        <button className="hover:bg-teal-600 bg-gray-900 text-white font-bold py-2 px-4 rounded-full mt-3">
          See all projects <FaChevronRight className="inline" />
        </button>
      </Link>
    </section>
  )
}

export default ProjectsList
